import React, { useEffect } from 'react';
import Title from './Title';
import Project from './Project';

const Portfolio = ({ isDarkMode, projects }) => {
    return (
        <div className={`background-color-alternate${isDarkMode ? '-darktheme':''}`} id={'portfolio'}>
                <Title
                    subTitle={'My Portfolio'}
                />
                {projects ? Object.keys(projects).map((key, index) => (
                    <Project index ={index} key={index} title={key} description={projects[key].description} link={projects[key].link} github={projects[key].github} img={projects[key].img}/>
                )): "hello"}
        </div>
    );
}
 
export default Portfolio;