import Image from "react-bootstrap/Image";
import selfie from "../images/selfie.jpg";
import Title from "./Title";
import { Col, Container, Row } from "react-bootstrap";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { IconButton } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Banner = ({ isDarkMode }) => {
    return (
        <div
            className={`background-color${isDarkMode ? "-darktheme" : ""}`}
            id="home"
        >
            <Container className="banner-container">
                <Row id="banner-text-container">
                    <Col lg={4}>
                        <Title
                            title={`Nicolo Tabar`}
                            description={`Software developer from Montreal, Canada`}
                        />
                        <p>
                            Passionate software developer dedicated to creating
                            high quality work.
                        </p>
                        <IconButton
                            href="https://www.linkedin.com/in/nicolo-tabar/"
                            target="_blank"
                        >
                            <LinkedInIcon sx={{ color: "#9336CC" }} />
                        </IconButton>
                        <IconButton
                            href="https://github.com/nicolo1"
                            target="_blank"
                        >
                            <GitHubIcon sx={{ color: "#9336CC" }} />
                        </IconButton>
                    </Col>
                    <Col lg={8}>
                        <LazyLoadImage
                            effect="blur"
                            id="banner-image"
                            src={`${selfie}`}
                            srcSet={`${selfie}`}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Banner;
