import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { DarkModeSwitch } from 'react-toggle-dark-mode';

const Header = ({isDarkMode, setDarkMode}) => {
  const expand = 'sm';
  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
  }; 

  const toggle = () => {
    return (
      <DarkModeSwitch
        style={{  marginTop:'10px', marginLeft:'14px' }}
        checked={isDarkMode}
        onChange={toggleDarkMode}
        size={18}
      />
    );
  }

  return (
    <div id='header'>

    <Navbar variant="light" className={`navbar-header header-color${isDarkMode ? '-darktheme':''}`}>
      <Container>
        <Navbar.Brand className={`clickable-text-global${isDarkMode ? '-darktheme':''}`} href="#home">Nicolo Tabar</Navbar.Brand>
          <Nav>
            <Nav.Link className={`clickable-text-global${isDarkMode ? '-darktheme':''}`} href="#portfolio">Portfolio</Nav.Link>
            <Nav.Link className={`clickable-text-global${isDarkMode ? '-darktheme':''}`} href="#about">About</Nav.Link>
            {toggle()}
          </Nav>
      </Container>
    </Navbar>
    </div>
  );
}

export default Header;