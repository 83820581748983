import React, { useEffect } from "react";
import Image from "react-bootstrap/Image";
import Title from "./Title";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Project = ({ index, img, title, link, github, description }) => {
    const image = () => {
        return (
            <LazyLoadImage
                effect="blur"
                className="project_image"
                src={img}
                srcSet={`${img}`}
                rounded
            />
        );
    };
    const text = () => {
        return (
            <Container>
                <Row className="project_description">
                    <p className="p-description">{description}</p>
                </Row>
                <Row className="project_link">
                    {link ? (
                        <Button
                            className="btn-global"
                            href={link}
                            target="_blank"
                        >
                            Website
                        </Button>
                    ) : (
                        ""
                    )}
                </Row>
                <Row className="project_github">
                    {github ? (
                        <Button
                            className="btn-global"
                            href={github}
                            target="_blank"
                        >
                            GitHub
                        </Button>
                    ) : (
                        ""
                    )}
                </Row>
            </Container>
        );
    };
    return (
        <div className="project-container">
            <Title projectTitle={title}></Title>
            <Container className="project_container">
                <Row>
                    <Col lg={6}>{image()}</Col>
                    <Col lg={6}>{text()}</Col>
                </Row>
            </Container>
        </div>
    );
};

export default Project;
