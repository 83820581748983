import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState } from 'react';
import { useEffect } from 'react';

import Header from './components/Header';
import Banner from './components/Banner';
import Portfolio from './components/Porfolio';
import About from './components/About';
import Footer from './components/Footer';

import Stack from 'react-bootstrap/Stack';

import { loadProjects } from './service/app';

function App() { 
  const [projects, setProjects] = useState(loadProjects());
  const [isDarkMode, setDarkMode] = React.useState(false);
  /*
    TODO: 
    1. put actual data
  */ 
  return (
    <div className={`app-body-color${isDarkMode ? '-darktheme':''}`}>
      <Stack>
        <Header isDarkMode={isDarkMode} setDarkMode={setDarkMode}></Header>
        <Banner isDarkMode={isDarkMode}></Banner>
        <Portfolio projects={projects} isDarkMode={isDarkMode}></Portfolio>
        <About isDarkMode={isDarkMode}></About>
        <Footer isDarkMode={isDarkMode}></Footer>
      </Stack>
    </div>
  );
}

export default App;
