import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Title from "./Title";
import selfie from "../images/selfie2.JPG";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const About = ({ isDarkMode }) => {
    return (
        <div
            className={`background-color${isDarkMode ? "-darktheme" : ""}`}
            id={"about"}
        >
            <Title subTitle={"About Me"} />
            <Container className="about_container">
                <Row>
                    <Col lg={5}>
                        <LazyLoadImage
                            effect="blur"
                            className="about_image"
                            src={selfie}
                            srcSet={`${selfie}`}
                        />
                    </Col>
                    <Col lg={7}>
                        <div className="about_description">
                            <p className="p-description">
                                As a recent computer science graduate, I am a
                                highly motivated individual with a passion for
                                high quality software development. I am a strong
                                team player and thrive in collaborative
                                environments with a team pushing towards a
                                common goal, and I am always seeking
                                opportunities to improve my skills.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default About;
