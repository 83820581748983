import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { IconButton } from "@mui/material";

const Footer = ({ isDarkMode }) => {
    return (
        <div
            className={`background-color-alternate${
                isDarkMode ? "-darktheme" : ""
            }`}
            id={"footer"}
        >
            <div className="footer-container">
                <p>Designed by Nicolo Tabar</p>
                <IconButton
                    href="https://www.linkedin.com/in/nicolo-tabar/"
                    target="_blank"
                >
                    <LinkedInIcon sx={{ color: "#9336CC" }} />
                </IconButton>
                <IconButton href="https://github.com/nicolo1" target="_blank">
                    <GitHubIcon sx={{ color: "#9336CC" }} />
                </IconButton>
            </div>
        </div>
    );
};

export default Footer;
