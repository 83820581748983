import React from 'react';

const Title = ({title, description, subTitle, projectTitle}) => {
    return (  
        <div className='title-container'>
            <h1 className='title'>{title}</h1>
            <h4 className='title-description'>{description}</h4>
            <h4 className='title-subTitle'>{subTitle}</h4>
            <h5 className='title-project'>{projectTitle}</h5>
        </div>
    );
}
 
export default Title;